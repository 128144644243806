<template>
  <div class="ss-tonnage">
    <div v-if="!isMobile" class="search-ton">
      <div class="ton-top">
        <el-card shadow="never" class="box-card">
          <el-form
            ref="emailtonform"
            :model="emailtonform"
            label-width="80px"
            :rules="rules"
            size="small"
          >
            <div class="form-left">
              <el-row>
                <el-col :span="10">
                  <el-form-item
                    :label="$t('message.search.vesselname')"
                    label-width="90px"
                    prop="shipVesselName"
                  >
                    <el-autocomplete
                      v-model="emailtonform.shipVesselName"
                      style="width: 80%"
                      :placeholder="$t('message.search.vesselname')"
                      :fetch-suggestions="querySearchVessel"
                      :trigger-on-focus="false"
                      @select="selectvessel"
                      @input="vesselname($event)"
                      @change="reselectvessel"
                    >
                      <template #default="{ item }">
                        <el-row>
                          <el-col :span="15"
                            ><span class="value">{{ item.value }}</span></el-col
                          >
                          <el-col :span="9">
                            <span class="link" style="font-size: smaller"
                              >{{ item.dwt }}/{{ item.built }}</span
                            ></el-col
                          >
                        </el-row>
                      </template>
                    </el-autocomplete>
                  </el-form-item>
                </el-col>
                <el-col :span="2"></el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('message.search.dwt')">
                    <el-col :span="6">
                      <el-form-item prop="dwtDown">
                        <el-input
                          v-model.number="emailtonform.dwtDown"
                          type="number"
                          @change="dwtDownchange"
                          :placeholder="$t('message.search.min')"
                          :min="0"
                          oninput="if(value<0)value=0"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="1">--</el-col>
                    <el-col :span="6">
                      <el-form-item prop="dwtUp">
                        <el-input
                          v-model.number="emailtonform.dwtUp"
                          type="number"
                          @change="dwtUpchange"
                          :placeholder="$t('message.search.max')"
                          :min="0"
                          oninput="if(value<0)value=0"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="1"></el-col>
                    <el-col :span="8">
                      <el-form-item prop="dwt_type">
                        <el-select
                          v-model="emailtonform.dwt_type"
                          :placeholder="$t('message.search.dwttype')"
                          @change="typechange"
                          prop="dwt_type"
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item
                    :label="$t('message.search.opendate')"
                    label-width="90px"
                  >
                    <el-col :span="11">
                      <el-form-item prop="openStartDate">
                        <el-date-picker
                          type="date"
                          value-format="yyyy-MM-dd"
                          :placeholder="$t('message.search.startdate')"
                          v-model="emailtonform.openStartDate"
                          style="width: 100%"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col class="line" :span="2">--</el-col>
                    <el-col :span="11">
                      <el-form-item prop="openEndDate">
                        <el-date-picker
                          type="date"
                          value-format="yyyy-MM-dd"
                          :placeholder="$t('message.search.enddate')"
                          v-model="emailtonform.openEndDate"
                          style="width: 100%"
                        ></el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="1"></el-col> -->
                <el-col :span="12">
                  <el-form-item :label="$t('message.search.openarea')">
                    <el-col :span="11">
                      <el-form-item prop="openseaarea">
                        <el-autocomplete
                          v-model="openseaarea"
                          :placeholder="$t('message.search.openarea')"
                          style="width: 100%"
                          :fetch-suggestions="querySearchOpenSea"
                          :trigger-on-focus="false"
                          @select="selectopensea"
                          @input="opensea($event)"
                          @change="changeopenSeaareaName"
                        >
                        </el-autocomplete>
                      </el-form-item>
                    </el-col>
                    <el-col class="line" :span="2">--</el-col>
                    <el-col :span="11">
                      <el-form-item prop="open_port">
                        <el-autocomplete
                          v-model="open_port"
                          :placeholder="$t('message.search.openport')"
                          style="width: 100%"
                          :fetch-suggestions="querySearchOpenPort"
                          :trigger-on-focus="true"
                          @select="selectopenport"
                          @input="openport($event)"
                          @change="reselectport"
                        >
                        </el-autocomplete>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10">
                  <el-form-item
                    :label="$t('message.search.vesselage') + '<'"
                    label-width="90px"
                    prop="years"
                  >
                    <el-select
                      v-model="emailtonform.built"
                      :placeholder="$t('message.search.vesselage')"
                    >
                      <el-option
                        v-for="item in options2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-button type="info" plain>{{
                      $t("message.years")
                    }}</el-button>
                  </el-form-item>
                </el-col>
                <el-col :span="2"></el-col>
                <el-col :span="10">
                  <el-form-item>
                    <el-button
                      class="search_btn"
                      type="primary"
                      :disabled="searchdisabled"
                      @click="getTonnageEmail"
                      >{{ $t("message.sidebar.search") }}</el-button
                    >
                    <el-button @click="resetForm('emailtonform')">{{
                      $t("message.reset")
                    }}</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div class="form-right"></div>
          </el-form>
        </el-card>
      </div>
      <el-tabs
        class="result_list"
        v-model="activeName"
        @tab-click="handleClick"
      >
        <!--列表显示信息-->
        <!--tableData[index].id-->
        <el-tab-pane :label="$t('message.list')" name="table">
          <el-table
            #default="scope"
            :data="tableData"
            :border="false"
            style="width: 100%"
            @row-click="goDetails"
            :header-cell-style="{
              background: '#fff',
              color: '#515a6e',
              'text-align': 'center',
            }"
            :cell-style="{ 'text-align': 'center' }"
            :show-overflow-tooltip="true"
          >
            <el-table-column
              prop="vessel_name"
              :label="$t('message.search.vesselname')"
              min-width="60"
            >
            </el-table-column>
            <el-table-column
              prop="dwt"
              :label="$t('message.search.dwt')"
              min-width="60"
            >
            </el-table-column>
            <el-table-column
              prop="built"
              :label="$t('message.tonnage.built')"
              min-width="80"
            >
            </el-table-column>
            <el-table-column
              prop="openStartDate"
              :label="$t('message.tonnage.openDate')"
              min-width="160"
            >
              <template #default="scope">
                <i class="el-icon-time"></i>
                <span style="margin-left: 5px"
                  >{{ scope.row.open_start_date }} ~
                  {{ scope.row.open_end_date }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="openSeaareaCname"
              :label="$t('message.tonnage.openArea')"
              min-width="100"
            >
              <template #default="scope">
                <span>{{ scope.row.open_area }},{{ scope.row.open_port }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('message.tonnage.createdTime')"
              :sortable="true"
              prop="create_time"
              min-width="80"
              :sort-method="SortTimeMethod"
            >
            <template #default="scope">
              {{getCreateTime(scope.row.create_time)}}
            </template>
            </el-table-column>
            <el-table-column :label="$t('message.handle')" min-width="120">
              <template #default="scope">
                <div class="actions">
                  <div class="actions-box">
                    <el-button
                      size="mini"
                      type="success"
                      @click.stop="goDetails(scope.row,scope.row.open_area,scope.row.open_port)"
                      plain
                      >{{ $t("message.detail") }}
                    </el-button>
                    <el-button
                      size="mini"
                      :disabled="editdisabled"
                      @click.stop="go_edit(scope.row)"
                      >{{ $t("message.edit") }}
                    </el-button>
                    <el-button
                      size="mini"
                      type="danger"
                      :disabled="deletedisabled"
                      @click.stop="open_Del(scope.row.id)"
                      >{{ $t("message.delete") }}
                    </el-button>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div class="list-footer">
        <el-pagination
          small
          :prev-text="$t('message.previous')"
          :next-text="$t('message.next')"
          layout="slot,prev, pager, next"
          background
          v-model:currentPage="currentPage1"
          :total="Math.ceil(pagination.total / 2)"
          :page-size="pagination.pagesize"
          @current-change="currentChange"
        />
      </div>
      <el-dialog
        :title="$t('message.email.deleteinformation')"
        v-model="del_dialog"
        width="30%"
        :before-close="handleClose"
      >
        <span>{{ $t("message.email.sentence3") }}</span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="del_dialog = false">{{
              $t("message.email.cancel")
            }}</el-button>
            <el-button type="primary" @click="go_del(editObj.id)">{{
              $t("message.email.ok")
            }}</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<!--      <el-tabs class="result_list" v-model="activeName">
        &lt;!&ndash;列表显示信息&ndash;&gt;
        &lt;!&ndash;tableData[index].id&ndash;&gt;
        <el-tab-pane :label="$t('message.list')" name="table">
          <el-table
              #default="scope"
              :data="tableData" :border="false"
              style="width: 100%"
              @row-click="goDetails"
              :header-cell-style="{background:'#fff',color:'#515a6e'}"
              :show-overflow-tooltip="true">
            <el-table-column type="index" width="50" />
            <el-table-column
                prop="subject"
                :label="$t('message.email.mailtheme')"
                min-width="70"
            >
            </el-table-column>
            <el-table-column
                prop="sender"
                :label="$t('message.email.sender')"
                min-width="120"
            >
            </el-table-column>
            <el-table-column
                prop="receiver"
                :label="$t('message.email.recipient')"
                min-width="120"
            >
            </el-table-column>
            <el-table-column
                prop="sendtime"
                :label="$t('message.email.sendtime')"
                min-width="100"
            >
              <template #default="scope">
                <span> {{editTime(scope.row.send_time, '{y}-{m}-{d} {h}:{i}:{s}')}}</span>
              </template>
            </el-table-column>
            <el-table-column
                :label="$t('message.handle')"
                min-width="120">
              <template #default="scope">
                <div class="actions">
                  <el-button
                      size="mini" type="success"
                      :disabled="detaildisabled"
                      @click.stop="open_Detail(scope.row)" plain>{{$t('message.detail')}}
                  </el-button>
                    <el-button
                        size="mini"
                        :disabled="editdisabled"
                        @click.stop="open_Edit(scope.row)">{{$t('message.edit')}}
                    </el-button>
                    <el-button
                        size="mini" type="danger"
                        :disabled="deletedisabled"
                        @click.stop="open_Del(scope.row.id)">{{$t('message.delete')}}
                    </el-button>
                  </div>
                  <div class="collect">
                    <star-box :fontSize="starFontSize"></star-box>
                  </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div  class="list-footer">
      <ul style="list-style: none">
        <li><el-button size="small" @click="prePage">{{$t('message.email.lastpage')}}</el-button></li>
        <li style="font-size:17px">{{pagination.current}}</li>
        <li><el-button size="small" @click="nextPage">{{$t('message.email.nextpage')}}</el-button></li>
        <li style="font-size:17px">共{{Math.ceil(pagination.total / 10)}}页</li>
      </ul>
      </div>
      <el-dialog
          :title="$t('message.email.modifyinformation')"
          v-model="deit_dialog"
          width="30%"
          :before-close="handleClose">
        <span>{{$t('message.email.sentence1')}}</span>
        <template #footer>
                <span class="dialog-footer">
                <el-button @click="deit_dialog = false">{{$t('message.email.cancel')}}</el-button>
                <el-button type="primary" @click="go_edit(editObj.id)">{{$t('message.email.ok')}}</el-button>
                </span>
        </template>
      </el-dialog>
      <el-dialog
          :title="$t('message.detail')"
          v-model="detail_dialog"
          width="30%"
          :before-close="handleClose">
        <span>{{$t('message.check_message')}}</span>
        <template #footer>
                <span class="dialog-footer">
                <el-button @click="detail_dialog = false">{{$t('message.email.cancel')}}</el-button>
                <el-button type="primary" @click="go_detail(detailObj.id)">{{$t('message.email.ok')}}</el-button>
                </span>
        </template>
      </el-dialog>
      <el-dialog
          :title="$t('message.email.deleteinformation')"
          v-model="del_dialog"
          width="30%"
          :before-close="handleClose">
        <span>{{$t('message.email.sentence3')}}</span>
        <template #footer>
                <span class="dialog-footer">
                <el-button @click="del_dialog = false">{{$t('message.email.cancel')}}</el-button>
                <el-button type="primary" @click="go_del(delObj.id)">{{$t('message.email.ok')}}</el-button>
                </span>
        </template>
      </el-dialog>
    </div>-->

<script>
import { mapGetters } from "vuex";
import ListCard from "../../components/list-card/ListCard.vue";
import StarBox from "../../components/star-box/StarBox";
import {
  deleteemail,
  searchemailbyid,
  tonnageemailcard,
  deletetonnageemail,
  tonnageemailpage,
  detailtonnage,
  getTonnageEmail,
  updateemailbyid,
  AreaName,
} from "../../api/emailextract";
import axios from "axios";
import { getToken } from "../../utils/auth";
import { parseTime } from "../../utils/index";
import { deleteTonnage } from "../../api/tonnage";
import { getseaarea } from "../../api/associativeinput";
/*export default {
  components:{ListCard,StarBox},
  data() {
    const validateEnd = (rule, value, callback) => {
      if (this.emailtonform.send_time_before > this.emailtonform.send_time_after) {
        callback(new Error(window.vm.$t('message.enddatevalide')))
      } else {
        callback()
      }
    };
    const validateStart = (rule, value, callback) => {
      if(this.emailtonform.sendendtime!==''){
      if (this.emailtonform.send_time_before > this.emailtonform.send_time_after) {
        callback(new Error(window.vm.$t('message.startdatevalide')))
      } else {
        callback()
      }}
    };
    return {
      editdisabled:'',
      deletedisabled:'',
      detaildisabled:'',
      emailtonform:{
        type:'tonnage',
        creator_id: localStorage.getItem('customerId'),
        receiver:'',
        subject:'',
        sender:'',
        send_time_after:'',
        send_time_before:''
      },
      pagination: {
        current: 1,
        pagesize: '',
        total: '',
        next:'',
        previous:''
      },
      options: [{
        value: '1',
        label: 'TONNAGE'
      }, {
        value: '2',
        label: 'CARGO'
      }, {
        value: '3',
        label: 'TCT'
      }
      ],
      options2:[
        {
          value: 5,
          label: '5'
        },
        {
          value: 10,
          label: '10'
        },
        {
          value: 15,
          label: '15'
        },
        {
          value: 20,
          label: '20'
        },
        {
          value: 25,
          label: '25'
        },
        {
          value: 30,
          label: '30'
        },
        {
          value: 35,
          label: '35'
        },
        {
          value: 40,
          label: '40'
        },
        {
          value: 50,
          label: '50'
        },
        {
          value: 60,
          label: '60'
        },
        {
          value: 70,
          label: '70'
        },
        {
          value: 80,
          label: '80'
        },
        {
          value: 90,
          label: '90'
        },
        {
          value: 100,
          label: '100'
        },
      ],
      activeName:'table',
      tableData:[],
      starFontSize:'28px',
      editObj:{}, //点击编辑按钮，传入的编辑对象
      detailObj:{},
      delObj:{},
      deit_dialog :false,
      detail_dialog:false,
      del_dialog:false,
      currentPage1:1,
      intid:'',
      rules:{
        send_time_before: [{ type: 'date', validator: validateStart,trigger: ['blur','change']}],
        send_time_after: [{ type: 'date', validator: validateEnd, trigger: ['blur','change']}],
      }
    }
  },
  computed:{
    ...mapGetters(['lang','isMobile'])
  },
  // activated(){
  //   this.getTonnageEmail();//获取列表数据的请求函数
  //   if (this.$route.meta.isBack) {
  //     // 这是详情页返回，可任意写自己需要处理的方法
  //     this.$route.meta.isBack = false // 重置详情页标识isBack
  //   }
  // },
  // activated() {
  //   if (!this.$route.meta.isBack) {
  //     this.tableData = [];
  //     //this.pageNum = 1;
  //     //this.getList();
  //   }
  //   this.$route.meta.isBack = false;
  // },
  // beforeRouteEnter(to, from, next) {
  //   console.log(to.path)
  //   console.log(from.path)
  //   var path=to.path.split('/')[1]+'/'+to.path.split('/')[2]
  //   console.log(path)
  //   if (path == "tonnage/addtonnage") {
  //     console.log('true')
  //     from.meta.keepAlive  = true;
  //   } else {
  //     from.meta.keepAlive  = false;
  //   }
  //   next();
  // },
  methods:{
    
    goDetails(row){
      this.intid= row.id
      this.$router.push(`/tonnage/addtonnage/${2}/${this.intid}`)
    },
    go_detail(){
      var  detailId= [this.detailObj]
      searchemailbyid(detailId).then((res)=>{
        if(res.error_code==200){
          this.$router.push(`/tonnage/addtonnage/${2}/${detailId}`)
          this.detail_dialog=false
        }
      })
    },
    go_edit(){
      var  editId= [this.editObj]
      updateemailbyid(editId).then((res)=>{
        if(res.error_code==200){
          this.$router.push(`/tonnage/addtonnage/${1}/${editId}`)
          this.deit_dialog=false
        }
      })
    },
    go_del(){
      var  deleteId= [this.delObj]
      deleteemail(deleteId).then(response =>{
        if(response.error_code==200){
          this.$message.success(window.vm.$t('message.tonnage.deletedinfo'))
          this.del_dialog=false
        }
      })
    },
    open_Edit(editObj) {
      this.editObj = editObj
      this.deit_dialog = true
    },
    open_Detail(detailObj){
      this.detailObj = detailObj
      this.detail_dialog = true
    },
    open_Del(delObj){
      this.delObj = delObj
      this.del_dialog = true
    },
    editTime(time,format){
     return parseTime(time,format)
    },
    //检索船舶动态信息
    getTonEmail(){
      getTonnageEmail(this.emailtonform).then(response =>{
        if(response.error_code ==200 ){
          this.tableData=response.data.results;
          // this.pagination.current = 1
          this.pagination.total = response.data.count
          this.pagination.next = (response.data.next).split('http://106.12.204.31:8002')[1]
          this.pagination.previous = (response.data.previous).split('http://106.12.204.31:8002')[1]
        }
      })
    },
    currentChange(n) {
      this.pagination.current = n
      this.getTonnageEmail()
    },
    nextPage(){
      axios({
        method: 'get',
        url:  this.pagination.next,
        headers: {
          Authorization: getToken(),
        }
      }).then(res => {
        console.log(res)
        this.tableData= res.data.results;
        this.pagination.total = res.data.count
        this.pagination.next = (res.data.next).split('http://106.12.204.31:8002')[1]
        this.pagination.previous = (res.data.previous).split('http://106.12.204.31:8002')[1]
      })
      if(this.pagination.total/10 >1&& this.pagination.current< this.pagination.total/10 ){
        this.pagination.current ++
      }
    },
    prePage(){
      axios({
        method: 'get',
        url:  this.pagination.previous,
        headers: {
          Authorization: getToken(),
        }
      }).then(res => {
        console.log(res)
        this.tableData= res.data.results;
        this.pagination.total = res.data.count
        this.pagination.next = (res.data.next).split('http://106.12.204.31:8002')[1]
        this.pagination.previous = (res.data.previous).split('http://106.12.204.31:8002')[1]
      })
      if(this.pagination.total/10 >1&& this.pagination.current> 1 ){
        this.pagination.current --
      }
    },
    senderEmail(e){
      var value = e.data;
      if(value ==null)
        this.emailtonform.sendername = '';
    },
    recipient(e){
      var value = e.data;
      if(value ==null)
        this.emailtonform.recipient = '';
    },
    resetForm(){
      this.emailtonform.subject = '';
      this.emailtonform.sender = '';
      this.emailtonform.receiver = '';
      this.emailtonform.send_time_before = '';
      this.emailtonform.send_time_after = '';
    }
  },
  created() {
    this.getTonEmail()
  }
}*/
export default{
  inject: ["reload"],
  components: { ListCard, StarBox },
  data() {
    const validatedwtDown = (rule, value, callback) => {
      if (this.emailtonform.dwtUp !== "") {
        if (
          parseInt(this.emailtonform.dwtDown) >
            parseInt(this.emailtonform.dwtUp) ||
          parseFloat(this.emailtonform.dwtDown) >
            parseFloat(this.emailtonform.dwtUp)
        ) {
          callback(new Error(window.vm.$t("message.minmessage")));
        } else {
          callback();
        }
      }
    };
    const validatedwtUp = (rule, value, callback) => {
      if (
        parseInt(this.emailtonform.dwtDown) >
          parseInt(this.emailtonform.dwtUp) ||
        parseFloat(this.emailtonform.dwtDown) >
          parseFloat(this.emailtonform.dwtUp)
      ) {
        callback(new Error(window.vm.$t("message.maxmessage")));
      } else {
        callback();
      }
    };
    const validateOpenStart = (rule, value, callback) => {
      if (
        this.emailtonform.openEndDate !== "" &&
        this.emailtonform.openStartDate > this.emailtonform.openEndDate
      ) {
        callback(new Error(window.vm.$t("message.startdatevalide")));
      } else {
        callback();
      }
    };
    const validateopenEndDate = (rule, value, callback) => {
      if (this.emailtonform.openEndDate !== "") {
        if (this.emailtonform.openStartDate > this.emailtonform.openEndDate) {
          callback(new Error(window.vm.$t("message.enddatevalide")));
        } else {
          callback();
        }
      }
    };
    return {
      open_seaarea: "",
      open_port: "",
      openseaarea: "",
      years: "",
      searchdisabled: "", //按钮权限
      buttonpermission: [], //按钮权限数组
      detaildisabled: "",
      editdisabled: "",
      deletedisabled: "",
      matchdisabled: "",
      emailtonform: {
        type: "tonnage",
        creator_id: localStorage.getItem("customerId"),
        dwtDown: "",
        dwtUp: "",
        openStartDate: "",
        openEndDate: "",
        built: "",
        sendTime: "",
        languageCode: "",
        openPortId: "",
        openPortCname: "",
        openPortEname: "",
        openSeaareaId: "",
        openSeaareaCname: "",
        openSeaareaEname: "",
        shipId: "",
        shipVesselName: "",
        shipVesselCname: "",
        shipVesselEname: "",
      },
      // pagination: {
      //   current: 0,
      //   pagesize: 6,
      //   total: 0
      // },
      pagination: {
        current: 1,
        page: 10,
        total: 0,
      },
      options: [
        {
          value: "0",
          label: "Other",
        },
        {
          value: "1",
          label: "Small Handy",
        },
        {
          value: "2",
          label: "Handysize",
        },
        {
          value: "3",
          label: "Large Handy",
        },
        {
          value: "4",
          label: "Handy Max",
        },
        {
          value: "5",
          label: "Super Max",
        },
        {
          value: "6",
          label: "Ultra Max",
        },
        {
          value: "7",
          label: "Pana Max",
        },
        {
          value: "8",
          label: "Kamsar Max",
        },
        {
          value: "9",
          label: "Post Panamax",
        },
        {
          value: "10",
          label: "Afra Max",
        },
        {
          value: "11",
          label: "Capesize",
        },
        {
          value: "12",
          label: "VLCC",
        },
        {
          value: "13",
          label: "ULCC",
        },
      ],
      options2: [
        {
          value: 5,
          label: "5",
        },
        {
          value: 10,
          label: "10",
        },
        {
          value: 15,
          label: "15",
        },
        {
          value: 20,
          label: "20",
        },
        {
          value: 25,
          label: "25",
        },
        {
          value: 30,
          label: "30",
        },
        {
          value: 35,
          label: "35",
        },
        {
          value: 40,
          label: "40",
        },
        {
          value: 50,
          label: "50",
        },
      ],
      activeName: "table",
      tableData: [],
      starFontSize: "28px",
      editObj: {}, //点击编辑按钮，传入的编辑对象
      matchObj: {},
      delObj: {},
      deit_dialog: false,
      match_dialog: false,
      del_dialog: false,
      currentPage1: 1,
      intid: "",
      rules: {
        dwtDown: [
          {
            validator: validatedwtDown,
            type: "number",
            trigger: ["blur", "change"],
          },
        ],
        dwtUp: [
          {
            validator: validatedwtUp,
            type: "number",
            trigger: ["blur", "change"],
          },
        ],
        openEndDate: [
          { validator: validateopenEndDate, trigger: ["blur", "change"] },
        ],
        openStartDate: [
          { validator: validateOpenStart, trigger: ["blur", "change"] },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      "lang",
      "permission",
      "isMobile",
      "portnamegroup",
      "seanamegroup",
      "vesselnamegroup",
    ]),
  },

  methods: {
      /*jx add 24.2.8 新增功能：按抽取时间排序*/
      SortTimeMethod({ column, prop, order }) 
    {
      if (order === 'ascending') {
        // 顺序排序逻辑
        this.tableData.sort((a, b) => a[prop] - b[prop]);
      } else if (order === 'descending') {
        // 倒序排序逻辑
        this.tableData.sort((a, b) => b[prop] - a[prop]);
      }
    },
    /********************/
    editTime(time, format) {
      return parseTime(time, format);
    },
    goDetails(row,areanaem,portname) {
      this.intid = row.id;
      this.$router.push({
        path: `/tonnage/addtonnage/${2}/${this.intid}`,
        query: {
          extract: true,
          email_detail: row.emaildetail_id,
          OpenAreaName:areanaem,
          OpenPortName:portname,
        },
      });
    },
    go_edit(row) {
      this.$router.push({
        path: `/tonnage/addtonnage/${1}/${row.id}`,
        query: {
          extract: true,
          email_detail: row.emaildetail_id,
        },
      });
    },
    go_match(obj) {
      this.$store.commit("metadata/SET_Tonnageinfo", obj);
      this.$router.push(`/tonnage/tonmatching/${obj.id}`);
    },
    go_del() {
      deletetonnageemail(this.delObj).then((response) => {
        if (response.error_code == 200) {
          this.$message.success(window.vm.$t("message.tonnage.deletedinfo"));
          this.del_dialog = false;
          this.getTonnageEmail();
        }
      });
    },
    open_Match(matchObj) {
      this.matchObj = matchObj;
      this.match_dialog = true;
    },
    open_Edit(editObj) {
      this.editObj = editObj;
      this.deit_dialog = true;
    },
    open_Del(delObj) {
      this.delObj = delObj;
      this.del_dialog = true;
    },
    //不点联想输入也可以正常搜索，船名，海域，港口
    reselectvessel() {
      if (this.vesselnamegroup.length != 0) {
        for (let i = 0; i < this.vesselnamegroup.length; i++) {
          if (
            this.vesselnamegroup[i].value == this.emailtonform.shipVesselName
          ) {
            this.selectvessel(this.vesselnamegroup[i]);
            this.emailtonform.shipVesselName = this.vesselnamegroup[i].value;
            break;
          }
        }
      }
    },
    reselectsea() {
      if (this.seanamegroup.length != 0) {
        for (let i = 0; i < this.seanamegroup.length; i++) {
          if (this.seanamegroup[i].value == this.openseaarea) {
            this.selectopensea(this.seanamegroup[i]);
            break;
          }
        }
      }
    },
    reselectport() {
      if (this.portnamegroup.length != 0) {
        for (let i = 0; i < this.portnamegroup.length; i++) {
          if (this.portnamegroup[i].value == this.open_port) {
            this.selectopenport(this.portnamegroup[i]);
            break;
          }
        }
      }
    },
    //检索船舶动态信息
    getTonnageEmail() {
      if (this.lang == "en") {
        this.emailtonform.languageCode = 2;
      } else {
        this.emailtonform.languageCode = 1;
      }
      const params = {
        query: this.emailtonform,
        pagination: this.pagination,
      };
      tonnageemailcard(params).then((response) => {
        if (response.error_code == 200) {
          this.tableData = response.datas;
          this.pagination.total = response.pagination.total;
        }
      });
    },
    typechange(obj) {
      if (this.options[obj].label == "Other") {
        this.emailtonform.dwtDown = null;
        this.emailtonform.dwtUp = null;
      } else if (this.options[obj].label == "Small Handy") {
        this.emailtonform.dwtDown = 0;
        this.emailtonform.dwtUp = 10000;
      } else if (this.options[obj].label == "Handysize") {
        this.emailtonform.dwtDown = 10000;
        this.emailtonform.dwtUp = 40000;
      } else if (this.options[obj].label == "Large Handy") {
        this.emailtonform.dwtDown = 30000;
        this.emailtonform.dwtUp = 39999;
      } else if (this.options[obj].label == "Handy Max") {
        this.emailtonform.dwtDown = 40000;
        this.emailtonform.dwtUp = 50000;
      } else if (this.options[obj].label == "Super Max") {
        this.emailtonform.dwtDown = 50000;
        this.emailtonform.dwtUp = 59999;
      } else if (this.options[obj].label == "Ultra Max") {
        this.emailtonform.dwtDown = 60000;
        this.emailtonform.dwtUp = 64999;
      } else if (this.options[obj].label == "Pana Max") {
        this.emailtonform.dwtDown = 65000;
        this.emailtonform.dwtUp = 79999;
      } else if (this.options[obj].label == "Kamsar Max") {
        this.emailtonform.dwtDown = 80000;
        this.emailtonform.dwtUp = 89999;
      } else if (this.options[obj].label == "Post Panamax") {
        this.emailtonform.dwtDown = 90000;
        this.emailtonform.dwtUp = 99999;
      } else if (this.options[obj].label == "Afra Max") {
        this.emailtonform.dwtDown = 80000;
        this.emailtonform.dwtUp = 110000;
      } else if (this.options[obj].label == "Capesize") {
        this.emailtonform.dwtDown = 100000;
        this.emailtonform.dwtUp = 450000;
      } else if (this.options[obj].label == "VLCC") {
        this.emailtonform.dwtDown = 150000;
        this.emailtonform.dwtUp = 320000;
      } else if (this.options[obj].label == "ULCC") {
        this.emailtonform.dwtDown = 320000;
        this.emailtonform.dwtUp = 550000;
      }
    },
    dwtDownchange(obj) {
      if (obj == 0 && this.emailtonform.dwtUp == 10000) {
        this.emailtonform.dwt_type = "Small Handy";
      } else if (obj == 10000 && this.emailtonform.dwtUp == 40000) {
        this.emailtonform.dwt_type = "Handysize";
      } else if (obj == 30000 && this.emailtonform.dwtUp == 39999) {
        this.emailtonform.dwt_type = "Large Handy";
      } else if (obj == 40000 && this.emailtonform.dwtUp == 50000) {
        this.emailtonform.dwt_type = "Handy Max";
      } else if (obj == 50000 && this.emailtonform.dwtUp == 59999) {
        this.emailtonform.dwt_type = "Super Max";
      } else if (obj == 60000 && this.emailtonform.dwtUp == 64999) {
        this.emailtonform.dwt_type = "Ultra Max";
      } else if (obj == 65000 && this.emailtonform.dwtUp == 79999) {
        this.emailtonform.dwt_type = "Pana Max";
      } else if (obj == 80000 && this.emailtonform.dwtUp == 89999) {
        this.emailtonform.dwt_type = "Kamsar Max";
      } else if (obj == 90000 && this.emailtonform.dwtUp == 99999) {
        this.emailtonform.dwt_type = "Post Panamax";
      } else if (obj == 80000 && this.emailtonform.dwtUp == 110000) {
        this.emailtonform.dwt_type = "Afra Max";
      } else if (obj == 100000 && this.emailtonform.dwtUp == 450000) {
        this.emailtonform.dwt_type = "Capesize";
      } else if (obj == 150000 && this.emailtonform.dwtUp == 320000) {
        this.emailtonform.dwt_type = "VLCC";
      } else if (obj == 320000 && this.emailtonform.dwtUp == 550000) {
        this.emailtonform.dwt_type = "ULCC";
      } else {
        this.emailtonform.dwtDown = obj;
        this.emailtonform.dwt_type = "other";
      }
    },
    dwtUpchange(obj) {
      if (obj == 10000 && this.emailtonform.dwtDown == 0) {
        this.emailtonform.dwt_type = "Small Handy";
      } else if (obj == 40000 && this.emailtonform.dwtDown == 10000) {
        this.emailtonform.dwt_type = "Handysize";
      } else if (obj == 39999 && this.emailtonform.dwtDown == 30000) {
        this.emailtonform.dwt_type = "Large Handy";
      } else if (obj == 50000 && this.emailtonform.dwtDown == 40000) {
        this.emailtonform.dwt_type = "Handy Max";
      } else if (obj == 59999 && this.emailtonform.dwtDown == 50000) {
        this.emailtonform.dwt_type = "Super Max";
      } else if (obj == 64999 && this.emailtonform.dwtDown == 60000) {
        this.emailtonform.dwt_type = "Ultra Max";
      } else if (obj == 79999 && this.emailtonform.dwtDown == 65000) {
        this.emailtonform.dwt_type = "Pana Max";
      } else if (obj == 89999 && this.emailtonform.dwtDown == 80000) {
        this.emailtonform.dwt_type = "Kamsar Max";
      } else if (obj == 99999 && this.emailtonform.dwtDown == 90000) {
        this.emailtonform.dwt_type = "Post Panamax";
      } else if (obj == 110000 && this.emailtonform.dwtDown == 80000) {
        this.emailtonform.dwt_type = "Afra Max";
      } else if (obj == 450000 && this.emailtonform.dwtDown == 100000) {
        this.emailtonform.dwt_type = "Capesize";
      } else if (obj == 320000 && this.emailtonform.dwtDown == 150000) {
        this.emailtonform.dwt_type = "VLCC";
      } else if (obj == 550000 && this.emailtonform.dwtDown == 320000) {
        this.emailtonform.dwt_type = "ULCC";
      } else {
        this.emailtonform.dwtUp = obj;
        this.emailtonform.dwt_type = "other";
      }
    },
    //船名联想输入的查询
    querySearchVessel(queryString, callback) {
      this.$store.commit("metadata/querySearchVessel", queryString);
      callback(this.vesselnamegroup);
    },
    //开放海域联想输入
    querySearchOpenSea(queryString, callback) {
      this.$store.commit("metadata/querySearchSea", queryString);
      callback(this.seanamegroup);
    },
    //开放港口联想输入
    querySearchOpenPort(queryString, callback) {
      this.$store.commit("metadata/querySearchPort", {
        queryString: queryString,
        seaAreaId: this.emailtonform.openSeaareaId,
      });
      callback(this.portnamegroup);
    },
    //联想输入select
    selectvessel(item) {
      this.emailtonform.shipId = item.id;
      this.emailtonform.shipVesselName = item.value;
    },
    selectopensea(item) {
      // if (this.lang == "en") {
        // this.emailtonform.openSeaareaId = item.id;
        // this.emailtonform.openSeaareaCname = item.cname;
        // this.emailtonform.openSeaareaEname = item.value;
        
      // } else {
        // jx modify 24.03.24 由于后端数据库只能查到港口和海域的英文名，所以在这不做区别处理，只要英文名称
        this.emailtonform.openSeaareaId = item.id;
        this.emailtonform.openSeaareaCname = item.value;
        this.emailtonform.openSeaareaEname = item.ename;
      // }
    },
    selectopenport(item) {
      this.emailtonform.openPortId = item.id;
      // jx modify 24.03.24 由于后端数据库只能查到港口和海域的英文名，所以在这不做区别处理，只要英文名称
      // if (this.lang == "en") {
      //   this.emailtonform.openPortEname = item.ename;
        
      //   this.emailtonform.open_port = item.value;
      // } else {
        this.emailtonform.openPortCname = item.value;
        // jx modify 由open_port改为openPortEname
        this.emailtonform.openPortEname = item.ename;
      // }
      getseaarea(item.seaAreaId).then((res) => {
        this.emailtonform.openSeaareaId = res.data.id;
        this.emailtonform.openSeaareaEname = res.data.ename;
        this.emailtonform.openSeaareaCname = res.data.cname;
        if (this.lang == "en") {
          this.openseaarea = res.data.ename;
        } else {
          this.openseaarea = res.data.cname;
        }
      });
    },
    getCreateTime(time){
      let date = new Date(time)
      // jx modify 24.03.02.getMonth()是从0开始计数，所以+1
      return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()
    },
    currentChange(n) {
      this.pagination.current = n;
      //?page=2
      this.getTonnageEmail();
    },
    vesselname(e) {
      var value = e;
      if (value == null) this.vesselnamegroup = "";
      this.resetvesselname();
    },
    opensea(e) {
      var value = e;
      if (value == null) this.resetopensea();
    },
    openport(e) {
      var value = e;
      if (value == null) this.resetopenoprt();
    },
    resetvesselname() {
      this.emailtonform.shipId = "";
      this.emailtonform.shipVesselName = "";
    },
    resetopensea() {
      this.emailtonform.openSeaareaId = "";
      this.emailtonform.openSeaareaCname = "";
      this.emailtonform.openSeaareaEname = "";
    },
    resetopenoprt() {
      this.emailtonform.openPortId = "";
      this.emailtonform.openPortCname = "";
      this.emailtonform.openPortEname = "";
    },
    changeopenSeaareaName(value) {
      if (value == "") {
        this.emailtonform.openSeaareaId = "";
      } else {
        this.emailtonform.openPortId = "";
        this.emailtonform.openPortEname = "";
        this.emailtonform.openPortCname = "";
        this.open_port = "";
        this.reselectsea();
      }
    },
    //重置修改为直接刷新当前页面
    resetForm() {
      this.reload();
    },
  },
  created() {
    this.getTonnageEmail();
    this.buttonpermission =
      this.permission == null
        ? localStorage.getItem("permission")
        : this.permission;
    // console.log('按钮权限'+this.buttonpermission)
    if (this.buttonpermission.includes("62005")) {
      this.searchdisabled = false;
    } else this.searchdisabled = true;
    if (this.buttonpermission.includes("62006")) {
      this.editdisabled = false;
    } else this.editdisabled = true;
    if (this.buttonpermission.includes("62007")) {
      this.deletedisabled = false;
    } else this.deletedisabled = true;
    if (this.buttonpermission.includes("31000")) {
      this.matchdisabled = false;
    } else this.matchdisabled = true;
  },
};
</script>

<style lang="scss">
.ss-tonnage {
  width: 100%;
  height: 100%;
  //background: pink;
  .search-ton {
    width: 100%;
    height: 100%;
    .ton-top {
      width: 100%;
      /*height:26%;*/
      //background: blue;
      .box-card {
        width: 100%;
        height: 100%;
        border: none;
        .el-card__body {
          width: 100%;
          height: 100%;
          padding: 5px;
          .el-form {
            width: 100%;
            height: 100%;
            display: flex;
            .form-left {
              width: 80%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              //background: pink;
              .el-form-item {
                margin-bottom: 12px;
                #ship_years {
                  margin-left: 20px;
                }
                .search_btn {
                  width: 30%;
                }
                .el-button + .el-button {
                  width: 30%;
                  margin-left: 30%;
                }
              }
              #col_2 {
                color: #fff;
              }
            }
            .form-right {
              flex: 1;
              background: pink;
            }
          }
        }
      }
    }
    .result_list {
      margin-top: 1%;
      width: 100%;
      height: 70%;
      position: relative;
      .el-tabs__header {
        margin: 0;
      }
      .el-tabs__content {
        width: 100%;
        height: 85%;
        //background: brown;
        overflow-y: auto;
        #pane-card {
          width: 100%;
          height: 100%;
          .list-area {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            .list-item:hover {
              cursor: pointer;
            }
            .list-item {
              width: 30%;
              height: 220px;
              margin-bottom: 15px;
              margin-right: 2%;
              // background: #409EeF;
            }
          }
        }
        #pane-table {
          width: 100%;
          height: 100%;
          .el-table {
            margin-bottom: 30px;
            .actions {
              display: flex;
              justify-content: space-between;
              .actions-box {
                flex: 1;
                display: flex;
                justify-content: space-around;
                .el-button--mini {
                  padding: 3px 7px;
                }
              }
              .collect:hover {
                cursor: pointer;
              }
              .collect {
                width: 28px;
                height: 28px;
                margin-left: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
    .list-footer {
      width: 100%;
      display: flex;
      justify-content: center;
      ul,
      li {
        float: left;
        margin: 0px;
        padding: 0px;
      }
      #tiao {
        margin-right: 30px;
      }
    }
  }
  .search-ton-mobile {
    width: 100%;
    height: 100%;
    .ton-top {
      width: 100%;
      height: 46%;
      //background: blue;
      .box-card {
        width: 100%;
        height: 100%;
        .el-card__body {
          width: 100%;
          height: 100%;
          padding: 2px;
          .el-form {
            width: 100%;
            height: 100%;
            //display: flex;
            #vessel_name {
              margin-top: 1.5%;
            }
            .el-form-item {
              margin-bottom: 10px;
              #ship_years {
                margin-left: 20px;
              }
              .el-button + .el-button {
                margin-left: 25%;
              }
            }
            #col_2 {
              color: #fff;
            }
          }
        }
      }
    }
    h2 {
      display: flex;
      justify-content: center;
      margin-top: 5%;
    }
    .list-area-mobile {
      width: 96%;
      //height:90%;
      margin-left: 3.5%;
      display: flex;
      flex-wrap: wrap;
      //background: #aaa;
      overflow-y: hidden;
      .list-item:hover {
        cursor: pointer;
      }
      .list-item {
        width: 100%;
        margin-bottom: 15px;
        margin-right: 5%;
        // background: #409EeF;
      }
    }
    // .result_list{
    //     margin-top: 1%;
    //      width:100%;
    //      height:70%;
    //      .el-tabs__content{
    //          width:100%;
    //          height:85%;
    //          //background: brown;
    //          overflow-y: auto;
    //          #pane-card{
    //              width:100%;
    //              height:100%;
    //               .list-area{
    //                 width:100%;
    //                 height:100%;
    //                 display: flex;
    //                 flex-wrap: wrap;
    //                 .list-item:hover{
    //                     cursor: pointer;
    //                 }
    //                 .list-item{
    //                     width:30%;
    //                     //height:100px;
    //                     margin-bottom: 15px;
    //                     margin-right: 2%;
    //                 // background: #409EeF;
    //                 }
    //              }

    //          }
    //          #pane-table{
    //              width:100%;
    //              height:100%;
    //              .el-table{
    //                 margin-bottom: 30px;
    //                 .actions{
    //                     display: flex;
    //                     justify-content: space-between;
    //                     .collect:hover{
    //                         cursor: pointer;
    //                     }
    //                     .collect{
    //                         width:28px;
    //                         height:28px;
    //                         margin-left: 10px;
    //                         display: flex;
    //                         justify-content: center;
    //                         align-items: center;
    //                     }
    //                 }
    //             }
    //          }
    //      }
    // }
  }
}
</style>
